<template>
  <main id="pages-login-login-content" class="ragnarok">
    <div class="header has-text-centered">Sign In</div>

    <form action="/login" method="post" @submit="validateLoginForm">
      <div class="notification is-info has-text-centered" tabindex="0" ref="error" v-if="hasParam('credentials')" role="alert">
        Please enter a valid email address and password
      </div>
      <div class="notification is-info has-text-centered" tabindex="0" ref="error" v-if="hasParam('captcha')" role="alert">
        Please complete the CAPTCHA to proceed
      </div>
      <div class="notification is-info has-text-centered" tabindex="0" ref="error" v-if="hasParam('locked')" role="alert">
        Your account has been disabled.<br/>
        Please <a href="/contacts">contact customer service</a> for assistance.
      </div>
      <div class="notification is-success has-text-centered" v-if="notification" role="alert">
        <button class="delete" @click="dismiss()"></button>
        {{ notification }}
      </div>
      <div class="notification is-danger has-text-centered" v-if="errorNotification" role="alert">
        <button class="delete" @click="dismiss()"></button>
        {{ errorNotification }}
      </div>

      <input type="hidden" name="timezone" :value="timezone"></input>

      <div class="field email">
        <div class="control">
          <fieldify label-id="email" label="Email" error-field="form.email" :errors="errors" v-floating-label>
            <b-input aria-labelledby="email" aria-required="true" size="is-large" name="email" autofocus="autofocus" v-model="$v.form.email.$model" v-autofocus></b-input>
          </fieldify>
        </div>
      </div>
      <div class="field password">
        <div class="control has-icons-right">
          <fieldify label-id="password" label="Password" error-field="form.password" :errors="errors" v-floating-label>
            <b-input aria-labelledby="password" aria-required="true" size="is-large" type="password" name="password" v-model="$v.form.password.$model"></b-input>
            <span class="forgot icon is-right"><a href="/forgot-password" aria-label="Forgot? Reset your password">Forgot?</a></span>
          </fieldify>
        </div>
      </div>
      <div class="field captcha" v-if="recaptchaEnabled">
        <div class="g-recaptcha" :data-sitekey="recaptchaSiteKey"></div>
      </div>
      <div class="field remember">
        <div class="control has-text-centered">
          <b-checkbox type="checkbox" name="remember-me" value="true" checked="checked">Remember this device</b-checkbox>
        </div>
      </div>
      <div class="field sign-in">
        <div class="control">
          <button type="submit" class="button is-fullwidth is-large is-primary">Sign In</button>
        </div>
      </div>
      <input type="hidden" :name="csrfParam" :value="csrfValue" />
    </form>
    <div class="field sign-up has-text-centered">
      No Account? <a href="/signup/customer" aria-label="Create a new account">Start Here</a>
    </div>
  </main>
</template>

<script>
import Fieldify from "~/components/Fieldify.vue";
import {required, email} from "vuelidate/lib/validators";
import validationMixin from "../../../mixins/validation-support";

export default {
  name: "LoginPage",

  components: {
    Fieldify
  },

  mixins: [validationMixin],

  data() {
    return {
      notification: pageNotification || "",
      errorNotification: pageErrorNotification || "",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      recaptchaEnabled: (!!recaptchaSiteKey && requireLoginCaptcha),
      recaptchaSiteKey: recaptchaSiteKey,
      csrfParam: csrfParam ? csrfParam : "_csrfParam",
      csrfValue: csrfValue,
      errors: {},
      form: {
        email: null,
        password: null
      }
    }
  },

  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },

  computed: {
    params() {
      return new URLSearchParams(window.location.search);
    },
  },

  methods: {
    dismiss() {
      this.notification = null;
      this.errorNotification = null;
    },

    hasParam(type) {
      const targetType = ("credentials" == type ? "error" : type);
      return this.params.has(targetType);
    },

    validateLoginForm(e) {
      this.$v.$reset();
      this.$v.$touch();

      if (this.$v.$invalid) {
        e.preventDefault();
      }
    }
  },

  mounted() {
    if (this.$refs.error) {
      setTimeout(() => this.$refs.error.focus(), 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
  // To do after Nuxt, do not have variables and mixins accessible
</style>